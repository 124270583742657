// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useContext, useEffect, useState } from "react";
import { getRevenueExpensesSummary } from "layouts/receitaXdespesa/components/receitasxdespesas/revenueExpensesApi";
import { getRevenueExpensesYearDetails } from "layouts/receitaXdespesa/components/receitasxdespesas/revenueExpensesApi";

import { getMonthlySummary } from "layouts/operations/api/operationsApi";
import { getWeekExpensesDetails } from "layouts/receitaXdespesa/components/receitasxdespesas/revenueExpensesApi";
import { AuthContext } from "context/authContext";

import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import { getProperty } from "./api/dashboardApi";

function Dashboard() {
  const [property, setProperty] = useState([]);
  const [revenueXExpenses, setRevenueXExpenses] = useState()
  const [saudeFinanceiraData, setSaudeFinanceiraData] = useState({})
  const [evolucaoPatrimonialData, setEvolucaoPatrimonialDataa] = useState({})
  const [gastosSemanaData, setGastosSemanaData] = useState({})
  const [saudeFinanceiraPercentage, setSaudeFinanceiraPercentage] = useState(null);
  const [evolucaoPatrimonialPercentage, setEvolucaoPatrimonialPercentage] = useState(null)
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [screenWidth, setScreenWidth] = useState(null);

  useEffect(() => {
    //TODO: Ajustar essas chamadas para usar o customFetch;
    getPropertyPosition();
    getRevenueExpensesSummaryMethod();
    getSaudeFinanceiraChartData();
    getMonthlySummaryChartData();
    getSGastosSemanaChartData();

    setScreenWidth(getWindowDimensions().width)

    const onboarding = localStorage.getItem("patrimonio360onboarding-dashboard");
    setStepsEnabled(onboarding != 'true');
  }, []);



  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    getVariationCurrentMonthAndLastMonth();
  }, [saudeFinanceiraData]);

  useEffect(() => {
    getVariationCurrentMonthRevenueAndLastMonthRevenue();
  }, [evolucaoPatrimonialData])

  const getContabilDate = () => {
    const dataAtual = new Date();

    const meses = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    const mesPorExtenso = meses[dataAtual.getMonth()].substring(0, 3);
    const ano = dataAtual.getFullYear();

    return `${mesPorExtenso}/${ano}`;
  }

  const getPropertyPosition = async () => {
    await getProperty().then((data) => {
      setProperty(data)
    });
  };

  const getRevenueExpensesSummaryMethod = async () => {
    await getRevenueExpensesSummary().then((data) => {
      setRevenueXExpenses(data);
    })
  }

  const getSaudeFinanceiraChartData = async () => {
    await getRevenueExpensesYearDetails()
      .then(e => {
        setSaudeFinanceiraData(
          {
            labels: e.map(e => e.month.substring(0, 3)),
            datasets: { label: 'R$', data: e.map(e => e.value) }
          })
      })
  }

  const getSGastosSemanaChartData = async () => {
    await getWeekExpensesDetails()
      .then(e => {
        setGastosSemanaData(
          {
            labels: e.map(e => e.dayOfWeek),
            datasets: { label: 'R$', data: e.map(e => e.value) }
          })
      })
  }

  const getMonthlySummaryChartData = async () => {
    await getMonthlySummary()
      .then(e => {
        setEvolucaoPatrimonialDataa(
          {
            labels: e.map(e => e.month.substring(0, 3)),
            datasets: { label: 'R$', data: e.map(e => e.value) }
          })
      })
  }

  const getVariationCurrentMonthAndLastMonth = () => {
    let lastValue = saudeFinanceiraData?.datasets?.data[saudeFinanceiraData?.datasets?.data.length - 1];
    let initialValue = saudeFinanceiraData?.datasets?.data[saudeFinanceiraData?.datasets?.data.length - 2];

    if (typeof (lastValue) == 'number' && typeof (initialValue) == 'number') {
      let result = 100 - ((lastValue * 100) / initialValue);
      setSaudeFinanceiraPercentage(Number(result * -1).toFixed(2));
    }
  }

  const getVariationCurrentMonthRevenueAndLastMonthRevenue = () => {

    let lastValue = evolucaoPatrimonialData?.datasets?.data[evolucaoPatrimonialData?.datasets?.data.length - 1];
    let initialValue = evolucaoPatrimonialData?.datasets?.data[evolucaoPatrimonialData?.datasets?.data.length - 2];

    if (typeof (lastValue) == 'number' && typeof (initialValue) == 'number') {
      let result = 100 - ((lastValue * 100) / initialValue);
      setEvolucaoPatrimonialPercentage(Number(result * -1).toFixed(2));
    }
  }
  const onExit = () => {
    setStepsEnabled(false);
    // localStorage.setItem("patrimonio360onboarding", true);
  };

  const { user, isLoggedIn } = useContext(AuthContext);

  const stepsDesktop = [
    {
      element: '.menuHamburger',
      intro: 'Clicando aqui você pode abrir ou fechar o menu lateral para ver todas as funcionalidades',
    },
    {
      element: '.menu-dashboard',
      intro: 'No Menu "Dashboard", você pode ter uma visão geral do seu patrimônio',
    },
    {
      element: '.patrimonioTotal',
      intro: 'Esta caixa mostra o valor total do seu patrimônio',
      position: 'right',
      tooltipClass: 'myTooltipClass',
    },
    {
      element: '.receita',
      intro: 'Esta caixa mostra o valor de sua receita no mês atual',
    },
    {
      element: '.despesa',
      intro: 'Esta caixa mostra o valor de sua despesa no mês atual',
    },
    {
      element: '.saldoTotal',
      intro: 'Esta caixa mostra o saldo disponível e é o resultado de sua Receita - Despesas',
    },
    {
      element: '.gastosSemana',
      intro: 'Aqui é possivel acompanhar de forma resumida os gastos semanais, da semana corrente',
    },
    {
      element: '.evolucaoPatrimonial',
      intro: 'Patrimônio acumulado por mês',
    },
    {
      element: '.saudeFinanceira',
      intro: 'Representa qual foi o balanço mensal (diferença entra Receitas e Despesas)',
    },
    {
      element: '.tabelaAtivos',
      intro: 'Esta seção representa os ativos inseridos no menu "Nova operação"',
    },
  ];

  const handleBeforeChange = (nextStepIndex) => {
    if(screenWidth < 1200 && nextStepIndex == 0){
      setTimeout(() => {
        document.getElementsByClassName("menuHamburger")[0].click()
        
      }, 400);
    }

    if(screenWidth < 1200 && nextStepIndex == 2){
        document.getElementsByClassName("menuHamburger")[0].click()
    }
  };

  return (
    <DashboardLayout>
      <Steps
        enabled={stepsEnabled}
        steps={stepsDesktop}
        initialStep={screenWidth < 1200 ? 0 : 1}
        onExit={onExit}
        onBeforeChange={handleBeforeChange}
        onBeforeExit={false}
        exitOnOverlayClick={false}
        onComplete={() => localStorage.setItem("patrimonio360onboarding-dashboard", true)}
        options={{
          doneLabel: 'Vamos começar!',
          prevLabel: '<',
          nextLabel: '>'
        }}
      />
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5} className="patrimonioTotal">
              {/* <WalletSharp
                color={'#00000'} 
                height="50"
                width="50px"
              /> */}

              <ComplexStatisticsCard
                color="dark"
                icon="account_balance_wallet"
                title="Patrimonio total"
                count={
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(property?.reduce((acc, item) => acc + item.value, 0))}
                toolTipMessage='jao'
              // percentage={{
              //   color: "success",
              //   amount: "+55%",
              //   label: "than lask week",
              // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5} className="receita">
              <ComplexStatisticsCard
                icon="leaderboard"
                title={`Receita ${getContabilDate()}`}
                count={
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(revenueXExpenses?.revenue ?? '0')}
                toolTipMessage='jao'
              // percentage={{
              //   color: "success",
              //   amount: "+3%",
              //   label: "than last month",
              // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>

            <MDBox mb={1.5} className="despesa">
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title={`Despesas ${getContabilDate()}`}
                count={
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(revenueXExpenses?.expenses ?? '0')}
                toolTipMessage='jao'
              // percentage={{
              //   color: "success",
              //   amount: "+1%",
              //   label: "than yesterday",
              // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5} className="saldoTotal">
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Saldo disponível"
                count={
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(revenueXExpenses?.remaningValue ?? '0')}
                toolTipMessage='jao'
              // percentage={{
              //   color: "success",
              //   amount: "9",
              //   label: "Just updated",
              // }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} >
              <MDBox mb={3} className="gastosSemana">
                <ReportsBarChart
                  color="info"
                  title="Gastos semana"
                  description={
                    <>
                      {gastosSemanaData?.datasets?.data &&
                        <>
                          {/* Total de despesas esta semana: */}
                          <strong>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(gastosSemanaData.datasets.data.reduce((accumulator, curr) => { return accumulator + curr }, 0))}
                          </strong>
                        </>
                      }
                    </>
                  }
                  date="campaign sent 2 days ago"
                  chart={gastosSemanaData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3} className="evolucaoPatrimonial">
                <ReportsLineChart
                  color="success"
                  title="Evolução patrimonial"
                  description={
                    <>
                      {evolucaoPatrimonialPercentage &&
                        <>
                          <strong>({evolucaoPatrimonialPercentage > 0 && '+'}{evolucaoPatrimonialPercentage}%)</strong> comparado ao mês anterior.
                        </>}
                    </>
                  }
                  date="updated 4 min ago"
                  chart={evolucaoPatrimonialData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3} className="saudeFinanceira">
                <ReportsLineChart
                  color="dark"
                  title="Saúde Financeira"
                  description={
                    <>
                      {saudeFinanceiraPercentage &&
                        <>
                          <strong>({saudeFinanceiraPercentage > 0 && '+'}{saudeFinanceiraPercentage}%)</strong> comparado ao mês anterior.
                        </>
                      }
                    </>
                  }
                  date=""
                  chart={saudeFinanceiraData}
                  toolTipMessage="Aqui vai um gráfico que representa valores negativos para mais gastos que receita e positivo para mias receita que gastos"
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
