// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "layouts/receitaXdespesa/components/Transaction";
import { useEffect, useState } from "react";
import { getRevenueExpensesList } from "../receitasxdespesas/revenueExpensesApi";
import { CircularProgress, Pagination } from "@mui/material";
import KeepMountedModal from "components/Modal";
import AddNewRevenueExpenseForm from "../receitasxdespesas/addNewRevenueExpenseForm";
import styled from "styled-components";

function Transactions(props) {
  const { openSnackBar, hasNewTransaction, refreshSummaryBoxes } = props;
  const [reloadTransactionList, setReloadTransactionList] = useState(false);

  const [revenuesXExpensesList, setRevenuesXExpensesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    if (hasNewTransaction != undefined) {
      getRevenuesXExpensesListMethod();
    }
  }, [])

  useEffect(() => {
    if (hasNewTransaction || reloadTransactionList) {
      getRevenuesXExpensesListMethod();
    }
  }, [reloadTransactionList, hasNewTransaction, currentPage])


  useEffect(() => {

    if(revenuesXExpensesList?.length == 0){
      setCurrentPage(1);
    }
  }, [revenuesXExpensesList?.length]);

  const getRevenuesXExpensesListMethod = async () => {
    setIsLoading(true);

    await getRevenueExpensesList(null, null, 10, currentPage)
    .then((data) => {
      setTotalPages(data.pageCount);

      let groupedByMakeDate = data.results.reduce((acc, item) => {
        const date = item.makeDateString;

        // Se a data ainda não existe no acumulador, inicialize um array para ela
        if (!acc[date]) {
          acc[date] = [];
        }

        // Adicione o item ao array correspondente à data
        acc[date].push(item);

        return acc;
      }, {});

      const groupedArray = Object.entries(groupedByMakeDate).map(([makeDate, items]) => ({
        makeDate,
        items
      }));

      setRevenuesXExpensesList(groupedArray);
    })

    console.log(revenuesXExpensesList.length)

    setIsLoading(false);
  }

  const handleOpenModal = (id) => {
    setId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Extrato
          </MDTypography>
          <MDBox display="flex" alignItems="flex-start">
            <MDBox color="text" mr={0.5} lineHeight={0}>
              <Icon color="inherit" fontSize="small">
                date_range
              </Icon>
            </MDBox>
            <MDTypography variant="button" color="text" fontWeight="regular">
              01 - {revenuesXExpensesList[0]?.items[0]?.lastDayOfMonth}
              {revenuesXExpensesList[0]?.items[0]?.month} {new Date().getFullYear()}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pt={3} pb={2} px={2}>
          {isLoading
            ? <CircularProgress color="inherit" size={16} />
            :
            <>
              {revenuesXExpensesList?.map(e => {
                return (<div key={e.id}>
                  <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
                      {e.makeDate}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                  >
                    {e.items?.map(e => {
                      return (
                        <Transaction
                          color={e.type == 1 ? "success" : "error"}
                          icon={e.type == 1 ? "expand_less" : "expand_more"}
                          name={e.name}
                          description={e.makeDateString}
                          value={`R$ ${e.type == 1 ? e.value : '-' + e.value}`}
                          id={e.id}
                          onOpenEditModal={(id) => handleOpenModal(id)}
                        />
                      )
                    })}

                  </MDBox>

                </div>)
              })}


            </>
          }

        </MDBox>

        <KeepMountedModal
          title="Editar Receita x Despesa"
          content={<AddNewRevenueExpenseForm
            openSnackBar={(e) => openSnackBar(e)}
            title="Editar Receita x Despesa"
            id={id}
            closeModal={handleCloseModal}
            refreshList={() => setReloadTransactionList(!reloadTransactionList)}
            refreshSummaryBoxes={refreshSummaryBoxes}
          />}
          closeModal={handleCloseModal} // Passa o setter diretamente
          isOpen={openModal} // Passa o estado diretamente
        />
      </Card>

      {totalPages > 1 && (
        <StyledPagination
          sx={{ display: 'flex', justifyContent: 'center', paddingTop: 4 }}
          disabled={isLoading}
          count={totalPages}
          boundaryCount={1}
          onChange={(e, page) => setCurrentPage(page)}
          size="small"
        currentPage={2}
        />
      )}

    </>
  );
}

const StyledPagination = styled(Pagination)`
    .MuiPagination-ul button{
        background: radial-gradient(circle at 225% 577%, #f28371 0%, #f28371 84%, transparent 0%),#F0BA8E!important;
        opacity: 0.8; 

        &[disabled]{
            opacity: 0.4; 
        }

        &.Mui-selected {
            opacity: 0.9; 
            background: radial-gradient(circle at -136% 568%, #f28371 0%, #f28371 98%, transparent 0%),#F0BA8E!important
        }
    }

    .MuiPagination-ul li{
        padding: 0 2px;
    }
`;

export default Transactions;
