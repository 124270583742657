import * as React from 'react';
import { useMaterialUIController } from 'context';
import MDBox from 'components/MDBox';
import styled from 'styled-components';
import MDTypography from 'components/MDTypography';
import { useState } from 'react';
import MDButton from 'components/MDButton';
import { Card, CircularProgress, Grid, Icon } from '@mui/material';
import MDInput from 'components/MDInput';
import { updateAllocationTarget } from '../api/allocationApi';
import { useEffect } from 'react';

function FormMetaAlocacao(props) {
    const [isLoading, setIsLoagding] = useState(false);
    const [metaPercentualAtivos, setMetaPercentualAtivos] = useState([{ asset: '', percentage: null }]);
    const [uptadingAtivo, setUpdatingAtivo] = useState('');
    const [uptadingPercentual, setUptadingPercentual] = useState('');
    const [openSnackMessage, setOpenSnackMessage] = useState(null);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const { content, closeModal, isOpen, title, refreshGraph, currentData, openSnackBar } = props;

    useEffect(() => {
        if (currentData.length > 0) {
            setMetaPercentualAtivos(currentData.map(e => {
                return { asset: e.name, percentage: e.value }
            }))
        }

    }, []);

    const handleSave = async () => {
        setIsLoagding(true);

        const body = {
            assets: metaPercentualAtivos
            .filter(e => e.asset !== '' && e.percentage !== null)
        };

        await updateAllocationTarget(body)
            .then((data) => {
                setIsLoagding(false);
                if (data?.errorCode) {
                    console.log(data)
                    openSnackBar({ type: 'error', message: `${data?.message}` });
                    return;
                }
                refreshGraph();
                openSnackBar({ type: 'success', message: 'Meta de alocação atualizada com sucesso' });
                closeModal();
            })
            .catch(e => {
                setIsLoagding(false);
                openSnackBar({ type: 'error', message: `${e}` });
            });
    };

    const handleAddPercentage = () => {
        setMetaPercentualAtivos((prev) => [...prev, { asset: '', percentage: null }]);
    };

    const handleDeletePercentage = (index) => {
        const updatedList = metaPercentualAtivos.filter((_, i) => i !== index);
        setMetaPercentualAtivos(updatedList);
    };

    const handleChangeAtivo = (index, value) => {
        const updatedList = metaPercentualAtivos.map((item, i) =>
            i === index ? { ...item, asset: value } : item
        );
        setMetaPercentualAtivos(updatedList);
    };

    const handleChangePercentage = (index, value) => {
        const updatedList = metaPercentualAtivos.map((item, i) =>
            i === index ? { ...item, percentage: value } : item
        );
        setMetaPercentualAtivos(updatedList);
    };

    const handleOpenSnackBar = (e) => {
        setOpenSnackMessage({ type: e?.type, message: e?.message });
    }

    return (
        <StyledContainer>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <StyledMDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="dark">
                                    {title}
                                </MDTypography>
                            </StyledMDBox>
                            <MDBox pt={4} pb={3} px={3}>
                                <MDBox component="form" role="form">

                                    {metaPercentualAtivos?.map((item, key) => (
                                        <StyledFormLine key={key}>
                                            <StyledInput mb={2} id="input-ativo">
                                                <MDInput
                                                    type="text"
                                                    label="Tipo do ativo"
                                                    fullWidth
                                                    value={item.asset}
                                                    onChange={(e) => handleChangeAtivo(key, e.target.value)}
                                                />
                                            </StyledInput>
                                            <MDBox mb={2}>
                                                <MDInput
                                                    type="number"
                                                    label="Percentual de alocação"
                                                    fullWidth
                                                    value={item.percentage}
                                                    onChange={(e) => handleChangePercentage(key, e.target.value)}
                                                />
                                            </MDBox>
                                            <MDBox mb={2}>
                                                {metaPercentualAtivos.length === key + 1 ? (
                                                    <StyledButton
                                                        disabled={isLoading || metaPercentualAtivos.reduce((total, item) => { return total + Number(item.percentage) }, 0) > 100}
                                                        variant="gradient"
                                                        color="info"
                                                        fullWidth
                                                        onClick={handleAddPercentage}
                                                    >
                                                        {<Icon>add</Icon>}
                                                    </StyledButton>
                                                ) : (
                                                    <StyledButton
                                                        disabled={isLoading || metaPercentualAtivos.reduce((total, item) => { return total + Number(item.percentage) }, 0) > 100}
                                                        variant="gradient"
                                                        color="error"
                                                        fullWidth
                                                        onClick={() => handleDeletePercentage(key)}
                                                    >
                                                        {<Icon>delete_outline</Icon>}
                                                    </StyledButton>
                                                )}
                                            </MDBox>
                                        </StyledFormLine>
                                    ))}


                                    <MDBox display="flex" alignItems="center" ml={-1}>

                                    </MDBox>
                                    <MDBox mt={4} mb={1}>
                                        <MDButton
                                            disabled={isLoading || metaPercentualAtivos.reduce((total, item) => { return total + Number(item.percentage) }, 0) > 100}
                                            variant="gradient" color={"success"} fullWidth onClick={handleSave}>
                                            {isLoading
                                                ? <CircularProgress color="inherit" size={16} />
                                                :
                                                'Salvar'
                                            }

                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </StyledContainer>
    );
}

export default FormMetaAlocacao;

const StyledButton = styled(MDButton)`
    border-radius: 550px !important;
    min-height: 10px !important;
    min-width: 10px !important;
    height: 40px !important;
    width: 40px !important;

    span:nth-child(1){
        font-size: 1.1rem !important;
        font-weight: 900;
    }
`;

const StyledFormLine = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

const StyledMDBox = styled(MDBox)`
  background: radial-gradient(circle at 221% 201%, #f28371 0%, #f28371 65%, transparent 0%) ,#f0ba8d !important; 
`;

const StyledContainer = styled.div`
    width: 100%;

    [role="dialog"]{
        margin: 50px;
    }


    // @media screen and (max-width: 767px) {
    //     padding-top: 0px !important;
    //   }
`;

const StyledInput = styled(MDBox)`

[data-input] {
    & > div > div {
        // border: 1px solid red;
        height: 44px;
        
        & > input {
            padding: 0 5px !important;
        }
    }
}
`;