export const login = async (userName: string, pass: string) => {

    //TODO: tirar isso daqui e pegar o token do local storage;
    const loginPayload = {
        "login": userName,
        "senha": pass
    };

    return await fetch(`${process.env.REACT_APP_API}/Auth/Login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginPayload)
    })
        .then(async response => {
           
            return await response.json();
        })
   
};

export const authenticateGoogleUser = async (userName: string, name: string, imageUrl: string) => {

    //TODO: tirar isso daqui e pegar o token do local storage;
    const loginPayload = {
        "userName": userName,
        "name": name,
        "imageUrl": imageUrl
    };

    return await fetch(`${process.env.REACT_APP_API}/Auth/authenticate-user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'ApiKey': `${process.env.REACT_APP_APIKEY}`
        },
        body: JSON.stringify(loginPayload)
    })
        .then(response => {
            return response;
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error('Error:', error); // Trata os erros
        });
};

export const register = async (name: string, userName: string, pass: string) => {

    //TODO: tirar isso daqui e pegar o token do local storage;
    const loginPayload = {
        "name": name,
        "UserName": userName,
        "Password": pass
    };

    return await fetch(`${process.env.REACT_APP_API}/Auth/Register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginPayload)
    })
        .then(response => {
            return response;
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error('Error:', error); // Trata os erros
        });
};

export default login;