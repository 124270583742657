// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import styled from "styled-components";
import MDAvatar from "components/MDAvatar";

function Transaction({
  color,
  icon,
  name,
  description,
  value,
  id,
  image,
  customAssetIcon,
  flexDirection = "column",
  onOpenEditModal,
  quantity }) {

  return (
    <MDBox key={name} component="li" py={1} pr={2} mb={1}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center">
          {icon &&
            <MDBox mr={2}>
              <MDButton variant="outlined" color={color} iconOnly circular>
                <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
              </MDButton>
            </MDBox>
          }
          <MDBox display="flex" flexDirection={flexDirection}
            sx={flexDirection == 'row'
              && { alignItems: 'center' }
            }>
            {image && (
              <StyledMDAvatar src={image} bgColor={'dark'} name={name} size="sm" />
            )}
            {customAssetIcon && (
              <StyledMDAvatar src={image} bgColor={'dark'} name={name} size="sm" />
            )}
            
            {quantity && (
                 <StyledMDTypography variant="overline" fontWeight="medium" gutterBottom $flexDirection={flexDirection}>
                 {quantity} x
               </StyledMDTypography>
            )}

            <StyledMDTypography variant="button" fontWeight="medium" gutterBottom $flexDirection={flexDirection}>
              {name}
            </StyledMDTypography>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {description}
            </MDTypography>
          </MDBox>
        </MDBox>
        <StyledContainer>
          <MDTypography variant="button" color={color} fontWeight="medium" textGradient>
            {value}
          </MDTypography>
          <MDBox pl={2} onClick={() => onOpenEditModal(id)}>
            <MDButton variant="text" color='secondary' iconOnly size="large">
              <Icon>edit</Icon>
            </MDButton>
          </MDBox>
        </StyledContainer>
      </MDBox>
    </MDBox>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMDTypography = styled(MDTypography)`
  margin-bottom: ${({ $flexDirection }) => $flexDirection === 'row' ? '-4px !important' : '0'};
  padding-left: ${({ $flexDirection }) => $flexDirection === 'row' ? '10px' : '0'}
`;

const StyledMDAvatar = styled(MDAvatar)`
    @media (max-width: 400px) {
      width: 20px !important;
      height: 20px !important;
    }
`;

// Typechecking props of the Transaction
Transaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Transaction;
