import { Autocomplete, Card, CircularProgress, FormControlLabel, Grid, Icon, Radio, RadioGroup, TextField, ThemeProvider, createTheme } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
import postRevenueExpense, { deleteRevenueExpense, updateRevenueExpense } from "layouts/receitaXdespesa/components/receitasxdespesas/revenueExpensesApi";
import { deleteOperationById, getCustomCategories, getOperationById, updateOperation } from "../api/operationsApi";

function EditOperationForm({ title, id, openSnackBar, closeModal, refreshList, refreshSummaryBoxes }) {
    const [operationType, setOperationType] = useState(null);
    const [date, setDate] = useState('');
    const [value, setValue] = useState('');
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [assetTypeId, setAssetTypeId] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoagding] = useState(false);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [customCategories, setCustomCategories] = useState([]);
    const [customCategoryId, setCustomCategoryId] = useState('');
    const [category, setCategory] = useState('');

    useEffect(() => {
        if (id) {
            //TODO: arrumar aqui quando tiver o endpoint
            getOperation();
        }

        const onboarding = localStorage.getItem("patrimonio360onboarding-modal-revenuexexpenses");
        setStepsEnabled(onboarding != 'true');
    }, []);

    const getOperation = async () => {
        setIsLoagding(true);

        getUserCustomCategories();

        await getOperationById(id)
            .then(e => {
                setDate(e.acquiredAt);
                setValue(e.price);
                setName(e.name);
                setOperationType(e.type == 1 ? 'compra' : 'venda');
                setQuantity(e.quantity);
                setAssetTypeId(e.assetTypeId);
                setCustomCategoryId(e.customAssetCategoryId);
            });

        setIsLoagding(false);
    }

    const getUserCustomCategories = async () => {
        setIsLoagding(true);
        await getCustomCategories('')
            .then(e => {
                setCustomCategories(e?.map(res => {
                    return { id: res.id, label: res.name };
                }));
            });

        setIsLoagding(false);
    }

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#1976d2' },
            },
        },
        ptBR,
    );

    const handleDateChange = (date) => {
        const currentTime = dayjs().format('HH:mm:ss');

        const fullDateTime = dayjs(date).format(`YYYY-MM-DD`) + 'T' + currentTime;
        setDate(fullDateTime);
    };

    const handleSave = async () => {
        setIsLoagding(true);

        const body = {
            operationType: operationType === 'compra' ? 1 : 2,
            operationDate: date,
            description: '',
            averagePrice: value,
            name: name,
            quantity: quantity,
            customCategory: customCategoryId ?? category
        };

        if (id) {
            await updateOperation(id, body)
                .then((data) => {
                    openSnackBar({ type: 'success', message: 'Operação realizada com sucesso' });
                    closeModal();
                    refreshList();
                })
                .catch(e => {
                    openSnackBar({ type: 'error', message: `${e.message}` });
                });
            setIsLoagding(false);
        }
        //esse else nunca é usado... remover depois
        else {
            await postRevenueExpense(body)
                .then((data) => {
                    setIsLoagding(false);
                    if (data?.errorCode) {
                        openSnackBar({ type: 'error', message: `${data?.message}` });
                        return;
                    }

                    openSnackBar({ type: 'success', message: 'Operação realizada com sucesso' });
                    closeModal();
                    refreshList();
                    // refreshSummaryBoxes();
                })
                .catch(e => {
                    openSnackBar({ type: 'error', message: `${e}` });
                });
        }
    };

    const handleDelete = async () => {
        setIsLoagding(true);
        await deleteOperationById(id)
            .then((data) => {
                setIsLoagding(true);
                openSnackBar({ type: 'success', message: 'Operação realizada com sucesso' });
                closeModal();
                refreshList();
                // refreshSummaryBoxes();
            });
    };

    return (
        <StyledContainer>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <StyledMDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={operationType == 'receita' ? "success" : "error"}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="dark">
                                    {title}
                                </MDTypography>
                            </StyledMDBox>
                            <MDBox pt={4} pb={3} px={3}>
                                <MDBox component="form" role="form">
                                    <StyledInput mb={2} id="input-ativo" >
                                        <MDInput type="text" label="Nome" fullWidth classProp="formNewRevenueXExmpenseName"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            disabled
                                        />
                                    </StyledInput>

                                    {assetTypeId == 7 &&
                                        <StyledInput mb={2} id="input-ativo" className="ativoInput">
                                            <Autocomplete
                                                data-input
                                                className="input-ativox"
                                                freeSolo
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                value={customCategories.find(cat => cat.id === customCategoryId) || null}
                                                options={customCategories}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e, newValue) => {
                                                    console.log(newValue);
                                                    setCategory('');
                                                    setCustomCategoryId(newValue ? newValue.id : '');
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    onChange={(e) => {
                                                        setCustomCategoryId(null);
                                                        setCategory(e.target.value)
                                                    }}
                                                    label="Categoria" />}
                                            />
                                        </StyledInput>

                                    }
                                    <MDBox mb={2}>
                                        <MDInput type="number" label="Valor unitário" fullWidth className="formNewRevenueXExmpenseValue"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            disabled={isLoading} />
                                    </MDBox>

                                    <MDBox mb={2}>
                                        <MDInput type="number" label="Quantidade" fullWidth className=""
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)} disabled={isLoading} />
                                    </MDBox>

                                    <ThemeProvider theme={theme}>
                                        <LocalizationProvider
                                            adapterLocale="pt-br"
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <StyledDatePicker
                                                className="formNewRevenueXExmpenseDate"
                                                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                                // format="DD/MM/YYYY" 
                                                label="select date"
                                                onChange={handleDateChange}
                                                value={date ? dayjs(date) : null}
                                                disabled={isLoading}
                                                PopperProps={{
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, 10], // Deslocamento para ajustar a posição
                                                            }
                                                        }
                                                    ]
                                                }}

                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>

                                    <MDBox mb={2}>
                                        <RadioGroup
                                            className="formNewRevenueXExmpenseRadio"
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue={operationType}
                                            name="radio-buttons-group"
                                            row
                                            value={operationType}
                                            onChange={(e) => setOperationType(e.target.value)}
                                            disabled={isLoading}
                                        >
                                            <FormControlLabel value="compra" disabled={isLoading} control={<Radio />} label="Compra" />
                                            <FormControlLabel value="venda" disabled={isLoading} control={<Radio />} label="Venda" />
                                        </RadioGroup>
                                    </MDBox>
                                    <MDBox display="flex" alignItems="center" ml={-1}>

                                    </MDBox>
                                    <MDBox mt={4} mb={1}>
                                        <MDButton disabled={isLoading} variant="gradient" color="success" fullWidth onClick={handleSave}>
                                            {isLoading
                                                ? <CircularProgress color="inherit" size={16} />
                                                :
                                                'Salvar'
                                            }

                                        </MDButton>
                                    </MDBox>
                                    {id && (<MDBox mt={2} mb={1}>
                                        <MDButton disabled={isLoading} variant="gradient" color={"error"} fullWidth onClick={handleDelete}>
                                            {isLoading
                                                ? <CircularProgress color="inherit" size={16} />
                                                :
                                                <Icon>delete</Icon>
                                            }
                                        </MDButton>
                                    </MDBox>)}
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </StyledContainer>
    );
}


const StyledMDBox = styled(MDBox)`
  background: radial-gradient(circle at 221% 201%, #f28371 0%, #f28371 65%, transparent 0%) ,#f0ba8d !important; 
`;


const StyledInput = styled(MDBox)`
[data-input] {
    & > div > div {
        // border: 1px solid red;
        height: 44px;
        
        & > input {
            padding: 0 5px !important;
        }
    }
}
`;

const StyledContainer = styled.div`
    width: 100%;

    [role="dialog"]{
        margin: 50px;
    }


    // @media screen and (max-width: 767px) {
    //     padding-top: 0px !important;
    //   }
`;

const StyledDatePicker = styled(DatePicker)`
    width: 100%;
`;

export default EditOperationForm;