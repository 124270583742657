import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bgg.jpg";
import login, { authenticateGoogleUser } from "../api/authenticationApi";

// login
import GoogleLogin from "react-google-login";
import { gapi } from 'gapi-script';
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [pass, setPass] = useState('');
  const [isLoading, setIsLoagding] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(null);
  const [errors, setErrors] = useState({ userName: '', pass: '' });


  const clientId = "429303876753-fijpsiil2qt0amasetqsj1320a38rmj6.apps.googleusercontent.com";

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId })
    })
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();

  const handleSubmitLogin = async () => {
    if (!validateForm()) return;

    setIsLoagding(true);
    await login(userName, pass)
      .then(async e => {

        if (!e?.errorCode) {
          setIsLoagding(false);
          localStorage.setItem("tradez-token", `${e.accessToken}`);
          navigate('/dashboard');
        } else {
          setOpenSnackBar({ message: e?.message })
        }
        return e;
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => setIsLoagding(false))
  }

  const responseGoogle = (response) => {
    const { profileObj: { email, name, imageUrl } } = response;

    authenticateUser(email, name, imageUrl);
  }

  const authenticateUser = async (userName, name, image) => {
    setIsLoagding(true);
    await authenticateGoogleUser(userName, name, image)
      .then(e => {

        if (e.status == 200) {
          return e.json();
        }
      })
      .then(e => {
        localStorage.setItem("tradez-token", `${e.accessToken}`);
        navigate('/dashboard');
      })
      .catch(e => console.log(e))
      .finally(() => setIsLoagding(false))
  }

  const validateForm = () => {
    let valid = true;
    const newErrors = { userName: '', pass: '' };

    if (!userName) {
      newErrors.userName = "Email é obrigatório";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(userName)) {
      newErrors.userName = "Formato de email inválido";
      valid = false;
    }

    if (!pass) {
      newErrors.pass = "Senha é obrigatória";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };


  return (
    <>
      <BasicLayout image={bgImage}>
        <Card>
          <StyledContent
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDBox mt={-2} mb={-6}>
              <img width={250} src={`../p3602.png`} />
            </MDBox>

            <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>

            </Grid>
          </StyledContent>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  error={!!errors.userName}
                  onChange={e => setUserName(e.target.value)}
                  type="email"
                  label="Email"
                  fullWidth />
                {!!errors.userName && <StyledSpan>{errors.userName}</StyledSpan>}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  error={!!errors.pass}
                  onChange={e => setPass(e.target.value)}
                  type="password"
                  label="Password"
                  fullWidth />
                {!!errors.pass && <StyledSpan>{errors.pass}</StyledSpan>}
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton disabled={isLoading} onClick={handleSubmitLogin} variant="gradient" color="info" fullWidth>
                  {isLoading
                    ? <CircularProgress color="inherit" size={16} />
                    :
                    'login'
                  }
                </MDButton>
                <MDBox mt={2} mb={1} />

                <GoogleLogin
                  clientId={clientId}
                  onSuccess={responseGoogle}
                  render={renderProps => (
                    <MDButton disabled={isLoading} onClick={renderProps.onClick} variant="gradient" color="error" fullWidth>
                      {isLoading
                        ? <CircularProgress color="inherit" size={16} />
                        :
                        <GoogleIcon color="inherit" />
                      }
                    </MDButton>
                  )}
                // onFailure={responseGoogle}
                // icon={false}
                // cookiePolicy={'single_host_origin'} //testar isso aqui
                />

              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Ainda não se cadastrou?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Cadastre aqui
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>

      </BasicLayout>

      <MDSnackbar
        icon="check"
        open={openSnackBar}
        color="error"
        title={openSnackBar?.message}
        onClose={() => setOpenSnackBar(false)}
        close={() => setOpenSnackBar(false)}
      />
    </>
  );
}

const StyledContent = styled(MDBox)`
  background: radial-gradient(circle at 135% 651%, #f28371 79%, #f28371 1%, transparent 0%) ,#f0ba8d !important; 
`;

const StyledSpan = styled.span`
  color: red;
  font-size:10px;
  display:flex;
`;

export default Basic;
