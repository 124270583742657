// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import ExtratoMes from "layouts/receitaXdespesa/components/ExtratoMes";
import { useEffect, useState } from "react";
import { getRevenueExpensesYearDetails } from "../receitasxdespesas/revenueExpensesApi";

function ExtratoConsolidado() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getRevenueExpensesYearDetails()
      .then(data => {
        setData(data);
      })
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Extrato Consolidado
        </MDTypography>

        {/* TODO. Extrato Consolidado - Ver tudo botão (trello)
        <MDButton variant="outlined" color="info" size="small">
          view all
        </MDButton> */}
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {data?.map(e => (
            <ExtratoMes date={e.month} price={`R$ ${e.value}`} color={Number(e.value) > 0 ? 'success' : 'error'} />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ExtratoConsolidado;
