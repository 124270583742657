import React, { PureComponent } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'pens',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Xota',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

const data2 = Array.from({ length: 12 }, (_, i) => ({
    name: `Page ${String.fromCharCode(65 + (i % 26))}`, // Alterna de A a Z
    uv: Math.floor(1000 + Math.random() * 4000), // Valores aleatórios entre 1000 e 5000
    pv: Math.floor(1000 + Math.random() * 5000), // Valores aleatórios entre 1000 e 6000
    amt: Math.floor(2000 + Math.random() * 3000) // Valores aleatórios entre 2000 e 5000
}));


function AlocacaoPorAtivo() {

    return (
        <div style={{ width: '100%', height: 600 }}>
            <StyledResponsiveContainer width="100%" height="100%" className="bars-chart-resp">
                <BarChart
                barCategoryGap={'35%'}
                    width={500}
                    height={300}
                    data={data2}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="pv" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                    <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                </BarChart>
            </StyledResponsiveContainer>
        </div>
    );
}


const StyledResponsiveContainer = styled(ResponsiveContainer)`
    // g.recharts-layer.recharts-bar-rectangle path.recharts-rectangle{
    //     transform: scaleX(0.5);
    // }

`;

export default AlocacaoPorAtivo;