// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

import KeepMountedModal from '../../../../components/Modal/index.js'
import AddNewRevenueExpenseForm from './addNewRevenueExpenseForm.js'
import { useState } from "react";

function ReceitaXDespesa(props) {
  const { remaningValue, closeOnboarding, openSnackBar, refreshList } = props;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
    localStorage.setItem("patrimonio360onboarding-revenuexexpenses", true);
    closeOnboarding(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid onClick={handleOpenModal} item xs={12} md={12} xl={12}>
        <DefaultInfoCard
          classProp="addRevenueExpense"
          icon="add"
          title="Adicionar"
          description="Adicionar Receita ou Despesa"
          // value={`R$ ${remaningValue}` }
          // value={'Adicionar'}
          height="8rem"
          width="8rem"
          iconSize="large"
        />
      </Grid>
      <KeepMountedModal
        className="modalRevenueExpenses"
        title="jaoooo"
        content={<AddNewRevenueExpenseForm
          openSnackBar={(e) => openSnackBar(e)}
          title="Nova Receita x Despesa"
          closeModal={handleCloseModal}
          refreshList={refreshList}
          />
        }
        closeModal={handleCloseModal}
        isOpen={openModal} // Passa o estado diretamente
      />
    </>
  );
}


export default ReceitaXDespesa;
