// react-router-dom components
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function Logout() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("tradez-token");
        navigate("/authentication/sign-in");
    }, []);

    return (
       <></>
    );
}

export default Logout;
